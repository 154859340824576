<template>
  <ion-page>
    <ion-header>
      <MainToolbar isSubpage :title="i18n.$t('tools.upload-reports.title')" />
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-list>
        <ion-item lines="full" v-for="(file, index) in itemListFiles" :key="index" button detail @click="currentVisibleFile = file">
          <ion-label class="ion-text-wrap">
            <ion-text color="primary">
              <h3>{{ file.date }}{{ (file.permission != null) ? ' - ' : '' }}{{ i18n.$t(`tools.upload-reports.permissions.${file.permission}`) || file.permission }}</h3>
            </ion-text>
            <ion-text>
              <h2 class="file-name">{{ file.name }}</h2>
            </ion-text>
          </ion-label>
        </ion-item>
        <!-- Allows scrolling beyond the list to interact with items without the FAB blocking interaction -->
        <div id="over-scroll"></div>
      </ion-list>
      <ion-fab vertical="bottom" horizontal="start" slot="fixed">
        <input ref="uploadElement" @change="readItemListFiles($event.target.files);$event.target.value = null;" type="file" accept=".json,application/json" multiple class="invisible-input"/>
        <ion-fab-button @click="uploadElement.click()" color="secondary">
          <ion-icon :icon="folderOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button color="success">
          <ion-icon :icon="ellipsisVertical"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          <ion-fab-button :disabled="itemListFiles.length <= 0 || selectedMainCategory == null" @click="openUploadConfirmation()" color="success">
            <ion-icon :icon="cloudUpload"></ion-icon>
          </ion-fab-button>
          <ion-fab-button :disabled="visibleEntryTypes == null || Object.keys(visibleEntryTypes).length <= 0" @click="openHidingDialog()" color="success">
            <ion-icon :icon="eyeOff"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>
      <ion-modal
        :is-open="currentVisibleFile != null"
        css-class="modify-item-dialog"
        @didDismiss="currentVisibleFile = null">
        <ion-page>
          <ion-header>
            <ion-toolbar>
              <ion-title>{{ (currentVisibleFile != null) ? currentVisibleFile.date : '' }} | {{ (currentVisibleFile != null) ? currentVisibleFile.name : '' }}</ion-title>
              <ion-buttons slot="end">
                <ion-button @click="currentVisibleFile = null">{{ i18n.$t('default_interaction.close') }}</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>
          <ion-header>
            <ion-item v-if="currentVisibleFile != null">
              <ion-label position="stacked">{{ i18n.$t('tools.upload-reports.permission') }}</ion-label>
              <ion-select :value="currentVisibleFile.permission" @ionChange="currentVisibleFile.permission = ($event.target.value != null && $event.target.value.length) ? $event.target.value : null" :placeholder="i18n.$t('tools.upload-reports.unrestricted')">
                <ion-select-option v-for="(permission, index) in availablePermissions" :key="index" :value="permission">{{ i18n.$t(`tools.upload-reports.permissions.${permission}`) || permission }}</ion-select-option>
                <ion-select-option v-if="currentVisibleFile.permission != null && !(availablePermissions.includes(currentVisibleFile.permission))" :value="currentVisibleFile.permission">{{ i18n.$t(`tools.upload-reports.permissions.${currentVisibleFile.permission}`) || currentVisibleFile.permission }}</ion-select-option>
                <ion-select-option :value="null">{{ i18n.$t('tools.upload-reports.unrestricted') }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-header>
          <ion-content scroll-x="true">
            <pre>{{ (currentVisibleFile != null) ? currentVisibleFile.contents : '' }}</pre>
          </ion-content>
        </ion-page>
      </ion-modal>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-item lines="none" id="main-category-select">
          <ion-label>{{ i18n.$t('tools.upload-reports.main_category') }}</ion-label>
          <ion-select v-model="selectedMainCategory" :okText="i18n.$t('default_interaction.select')" :cancelText="i18n.$t('default_interaction.cancel')" :placeholder="i18n.$t('default_interaction.select')">
            <ion-select-option v-for="category in localizedMainCategories" :key="category.id" :value="category.id">{{ category.name }}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonFab, IonFabList, IonFabButton, IonIcon, IonList, IonItem, IonLabel, IonModal, IonToolbar, IonTitle, IonButtons, IonButton, IonText, IonFooter, IonSelect, IonSelectOption, alertController, toastController } from '@ionic/vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

import MainToolbar from '@/components/MainToolbar.vue';

import { folderOutline, cloudUpload, ellipsisVertical, eyeOff } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import { localError, apiErrorToast } from "@/utils/error";

export default  {
  name: 'UploadReports',
  components: { IonHeader, IonContent, IonPage, IonFab, IonFabList, IonFabButton, IonIcon, IonList, IonItem, IonLabel, IonModal, IonToolbar, IonTitle, IonButtons, IonButton, IonText, IonFooter, IonSelect, IonSelectOption, MainToolbar },
  setup() {
    const i18n = useI18n();

    const store = useStore();

    const uploadElement = ref(null);

    const itemListFiles = ref([]);

    const currentVisibleFile = ref(null);

    const selectedMainCategory = ref(null);

    const INTERNAL_PERMISSION = 'Internal';

    const availablePermissions = [INTERNAL_PERMISSION, 'Pilot_Klinik_Schweiz', 'Pilot_User_Schweiz_CC'];

    const entryTypePermissions = computed(() => {
      let reportTypeIndex = store.getters['reports/getReportTypeIndex'];

      let entryTypes = {};

      for (let [categoryId, category] of Object.entries(reportTypeIndex)){
        for (let [entryTypeName, entryType] of Object.entries(category)){
          if (selectedMainCategory.value == null || categoryId == selectedMainCategory.value) { //Get the last occurrence or just the one in the correct category, if selected
            if (entryType.versions != null && entryType.newest_version != null && entryType.versions[entryType.newest_version] != null) {
              entryTypes[entryTypeName] = entryType.versions[entryType.newest_version].needs_permission;
            }
          }
        }
      }

      return entryTypes;
    });

    const visibleEntryTypes = computed(() => {
      let reportTypeIndex = store.getters['reports/getReportTypeIndex'];

      let visibleTypes = {};

      for (let [categoryId, category] of Object.entries(reportTypeIndex)){
        for (let [entryTypeName, entryType] of Object.entries(category)){
          let visibleVersions = [];
          for (let [versionNumber, version] of Object.entries(entryType.versions)) {
            if (!version.disabled_for_new) {
              visibleVersions.push({
                ...version,
                version: versionNumber,
              });
            }
          }
          if (visibleVersions.length > 0){
            let nameWithCategory = categoryId + '_' + entryTypeName; //To catch the case where two with the same name exist in different categories
            visibleTypes[nameWithCategory] = {
              versions: visibleVersions,
              name: entryTypeName,
              order: (entryType.newest_version in entryType.versions) ? entryType.versions[entryType.newest_version].order : 0,
              categoryId
            };
          }
        }
      }

      return visibleTypes;
    });

    const readItemListFiles = function(files){
      itemListFiles.value = [];
      for (let file of files){
        try {
          //Remove file extension
          let fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');

          //Split up the string in date and the rest
          let fileNameParts = /^([0-9]{1,4}-[0-9]{1,2}-[0-9]{1,2}) (.*)$/.exec(fileNameWithoutExtension);

          if (fileNameParts == null || fileNameParts.length < 3 || fileNameParts[1] == null || fileNameParts[1].length == 0 || fileNameParts[2] == null || fileNameParts[2].length == 0) 
            throw { name: 'InvalidFilename', message: i18n.$t('tools.upload-reports.invalid_filename') + ' | ' + fileNameWithoutExtension };
          
          //Date at beginning of filename
          let entryDate = fileNameParts[1];
          //Rest of the filename trimmed
          let entryName = fileNameParts[2].trim();

          //Split up the date in its parts
          let dateParts = /^([0-9]{1,4})-([0-9]{1,2})-([0-9]{1,2})$/.exec(entryDate);

          if (dateParts == null || dateParts.length < 4 || dateParts[1] == null || dateParts[2] == null || dateParts[3] == null) 
            throw { name: 'InvalidDateFormat', message: i18n.$t('tools.upload-reports.invalid_date_format') + ' | ' + entryDate };

          let version = dateParts[1].padStart(4, '2021') + 
                        dateParts[2].padStart(2, '00') + 
                        dateParts[3].padStart(2, '00');

          let reader = new FileReader(); 
          reader.onload = function(){ 
            try {
              itemListFiles.value.push({
                name: entryName,
                date: entryDate,
                version: version,
                contents: JSON.parse(reader.result),
                permission: ((entryName in entryTypePermissions.value) ? entryTypePermissions.value[entryName] : INTERNAL_PERMISSION)
              });
            } catch (error) {
              localError(i18n, i18n.$t('tools.upload-reports.load_error'), error.message);
            }
          } 
                  
          reader.readAsText(file);
        } catch (error) {
          localError(i18n, i18n.$t('tools.upload-reports.load_error'), error.message);
        }
      }
    }

    const resetForm = function(){
      itemListFiles.value = [];
      selectedMainCategory.value = null;
      currentVisibleFile.value = null;
    }

    const uploadItemLists = function(){
      let creationPromises = [];

      for (let file of itemListFiles.value){
        let nameWithCategory = selectedMainCategory.value + '_' + file.name;
        creationPromises.push(
          store.dispatch('reports/createNewReportType', 
            {
              descriptor: file.name,
              version: file.version,
              definition: file.contents,
              main_category: selectedMainCategory.value,
              order: (visibleEntryTypes.value != null && nameWithCategory in visibleEntryTypes.value && visibleEntryTypes.value[nameWithCategory].order != null) ? visibleEntryTypes.value[nameWithCategory].order : 0,
              needs_permission: (file.permission != null) ? { type: file.permission } : undefined
            }
          )
        );
      }

      Promise.all(creationPromises).then(() => {
        toastController.create(
          {
            message: i18n.$t('tools.upload-reports.uploaded'),
            position: 'top',
            duration: 5000,
            color: 'success',
            buttons: [
              {
                text: i18n.$t('default_interaction.close'),
                role: 'cancel'
              }
            ]
          }
        ).then((toast) => {
          toast.present();
          resetForm();
        });
      })
      .catch((error) => {
        apiErrorToast(i18n, error);
      })
      .finally(() => {
        store.dispatch('reports/fetchReportTypeIndex'); //Update index, because something must have changed during the update
      });
    }          

    const hideEntryTypes = function(entryTypes){
      let hidePromises = [];

      for (let entryTypeArray of entryTypes){
        for (let entryType of entryTypeArray) {
          hidePromises.push(
            store.dispatch('reports/hideReportType', entryType.id)
          );
        }
      }

      Promise.all(hidePromises).then(() => {
        toastController.create(
          {
            message: i18n.$t('tools.upload-reports.hidden'),
            position: 'top',
            duration: 5000,
            color: 'success',
            buttons: [
              {
                text: i18n.$t('default_interaction.close'),
                role: 'cancel'
              }
            ]
          }
        ).then((toast) => {
          toast.present();
        });
      })
      .catch((error) => {
        apiErrorToast(i18n, error);
      })
      .finally(() => {
        store.dispatch('reports/fetchReportTypeIndex'); //Update index, because something must have changed during the update
      });
    }

    const openUploadConfirmation = async function(){
        const alert = await alertController
        .create({
          cssClass: 'upload-confirmation-alert',
          header: i18n.$t('tools.upload-reports.upload-confirmation.title'),
          message:  i18n.$t('tools.upload-reports.upload-confirmation.message.before') + 
                    '<b>' + itemListFiles.value.length + '</b>' + 
                    i18n.$t('tools.upload-reports.upload-confirmation.message.after') + 
                    ' <br/><br/> ' + i18n.$t('tools.upload-reports.upload-confirmation.message.newline') + 
                    '<b>' + getLocalizedMainCategory.value(selectedMainCategory.value) + '</b>',
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('tools.upload-reports.upload-confirmation.upload'),
              cssClass: 'upload-confirmation-okay',
              handler: () => {
                uploadItemLists();
              },
            },
          ],
        });
      return alert.present();
    }

    const getLocalizedMainCategory = computed(() => function(mainCategoryId){
      let categoryNames = store.getters['reports/getMainCategoryNamesById'](mainCategoryId);
      if (categoryNames) {
        return categoryNames[i18n.locale.value];
      } else {
        return null;
      }
    });

    const localizedMainCategories = computed(() => {
      let categories = store.getters['reports/getMainCategories'];
      let language = i18n.locale.value;
      if (categories != null) {
        return Object.values(categories).map(category => {return {id: category.id, name: category.name[language]}});
      } else {
        return null;
      }
    });

    const openHidingDialog = async function(){
        const alert = await alertController
        .create({
          cssClass: 'hiding-dialog-alert',
          header: i18n.$t('tools.upload-reports.hiding-dialog.title'),
          inputs: Object.values(visibleEntryTypes.value).map((entryType) => {
            return {
              type: 'checkbox',
              label: `${getLocalizedMainCategory.value(entryType.categoryId)} | ${entryType.name}`,
              value: entryType.versions,
              cssClass: 'select-entry-item'
            }
          }),
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('tools.upload-reports.hiding-dialog.hide'),
              cssClass: 'hiding-dialog-okay',
              handler: (data) => {
                hideEntryTypes(data);
              },
            },
          ],
        });
      return alert.present();
    }

    return { i18n, uploadElement, itemListFiles, currentVisibleFile, visibleEntryTypes, readItemListFiles, openUploadConfirmation, openHidingDialog, localizedMainCategories, selectedMainCategory, availablePermissions, folderOutline, cloudUpload, ellipsisVertical, eyeOff };
  }
}
</script>

<style>
.upload-confirmation-okay, .hiding-dialog-okay {
  color: var(--ion-color-success)!important;
}

/* Allow for longer texts in select and indent every line but the first using a negative offset on the first line in a wrapped text */
.select-entry-item .alert-radio-label, .select-entry-item .alert-checkbox-label {
  margin-left: 6px;
  white-space: normal!important;
  text-indent: -6px;
}

/* Fix that the radio buttons are displayed, even when wrapping more than two lines */
.select-entry-item .select-interface-option {
  height: auto;
  contain: content;
}

.select-entry-item {
  height: auto!important;
  contain: content!important;
}
</style>

<style scoped>
ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

pre {
  padding: 16px;
  position: absolute;
  overflow: visible;
  margin: 0px;
}

#over-scroll {
  width: 100%;
  height: 100px;
}

.invisible-input {
  visibility: hidden;
  display: none;
}

.file-name {
  padding-top: 5px;
}

#main-category-select ion-label {
  max-width: 100px;
}

ion-fab-button.fab-button-disabled {
  opacity: 0.5;
}
</style>
